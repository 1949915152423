import { useState, forwardRef } from "react";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import HTMLFlipBook from "react-pageflip";
import queryString from "query-string";
import "./App.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const Page = forwardRef(({ pageNumber, width }, ref) => {
  return (
    <div
      style={{ background: "white !important", width: "100%", height: "100%" }}
      ref={ref}
    >
      <ReactPdfPage pageNumber={pageNumber} width={width} />
    </div>
  );
});

function App() {
  const parsedQueryString = queryString.parse(window.location.search);
  const pdfFile = parsedQueryString.pdf || "";
  const screenWidth = Number(parsedQueryString.width) || 380;
  const screenHeight = Number(parsedQueryString.height) || 538;
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess(docData) {
    setNumPages(docData.numPages);
  }

  const generateArrayWithNumberOfItems = (numberOfItems) => {
    let array = [];
    for (let i = 1; i <= numberOfItems; i++) {
      array.push(i);
    }
    return array;
  };

  return (
    <div
      style={{
        overflow: "hidden",
        width: screenWidth,
        height: screenHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        userSelect: "none",
      }}
    >
      <Document
        width={screenWidth}
        height={screenHeight}
        onLoadSuccess={onDocumentLoadSuccess}
        file={pdfFile}
        loading="Đang tải..."
        noData="Không có file"
      >
        <HTMLFlipBook
          swipeDistance={10}
          width={screenWidth}
          height={screenHeight}
        >
          {generateArrayWithNumberOfItems(numPages).map((pageNumber) => (
            <Page
              key={pageNumber}
              pageNumber={pageNumber}
              width={screenWidth}
            />
          ))}
        </HTMLFlipBook>
      </Document>
    </div>
  );
}

export default App;
